<template>
    <div id="result">

        <div class="media py-3">
            <img :src="this.ppdb.pemkab_logo" class="align-self-end mr-3 pl-3" width="64">
            <div class="media-body text-center">
                <p class="h6 mt-0">{{this.ppdb.event}}</p>
                <p class="h6 mt-0 font-weight-normal">{{ this.ppdb.address }}</p>
            </div>
            <img :src="this.ppdb.school_logo ? this.ppdb.school_logo : 'https://ppdb.soalonline.com/image/default.jpg'" class="align-self-end ml-3 pr-3" width="64">
        </div>


        <div v-if="this.result">
            <div class="h6 bg-secondary text-light p-1 px-3">Jalur Zonasi</div>
            <b-table bordered small striped responsive :items="this.zonasi"></b-table>

            <div class="h6 bg-secondary text-light p-1 px-3">Jalur Prestasi</div>
            <b-table bordered small striped responsive :items="this.prestasi"></b-table>

            <div class="h6 bg-secondary text-light p-1 px-3">Jalur Afirmasi</div>
            <b-table bordered small striped responsive :items="this.afirmasi"></b-table>

            <div class="h6 bg-secondary text-light p-1 px-3">Jalur Mutasi</div>
            <b-table bordered small striped responsive :items="this.mutasi"></b-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Pengumuman PPDB Online',
  },
  data() {
      return {
          code: null,
          ppdb: null,
          result: null,
          afirmasi: [{no: '--', registrasi: '--', nama_lengkap: '--'}],
          mutasi:  [{no: '--', registrasi: '--', nama_lengkap: '--'}],
          prestasi:  [{no: '--', registrasi: '--', nama_lengkap: '--'}],
          zonasi:  [{no: '--', registrasi: '--', nama_lengkap: '--'}],
      }
  },
  mounted() {
    const code = this.$route.params.code;
    let durl = this.$endpoint.result
    let ddata = {'code': code}
    // console.log(durl, ddata)
    axios.post( durl, ddata )
    .then(
        (response) => {
            
            // console.log(data)
            if (response.data.status == true ) {
                const data = response.data.data
                this.code = data.code
                this.ppdb = data.ppdb
                this.result = true
                if (data.result.AFIRMASI && data.result.AFIRMASI.length>0 ) {
                    this.afirmasi = data.result.AFIRMASI
                }
                if (data.result.ORTU_PINDAH && data.result.ORTU_PINDAH.length>0 ) {
                    this.mutasi = data.result.ORTU_PINDAH
                }
                if (data.result.PRESTASI && data.result.PRESTASI.length>0 ) {
                    this.prestasi = data.result.PRESTASI
                }
                if (data.result.ZONASI && data.result.ZONASI.length>0 ) {
                    this.zonasi = data.result.ZONASI
                }
                console.log('code: ', this.code)
                console.log('ppdb: ', this.ppdb)
            } else {
                console.log('api status false')
            }
        },
        (error) => {
            alert('Gagal terkoneksi ke server, periksa internet anda, atau refresh browser')
            console.log(error)
        }
    )
    console.log(code);
  },
}
</script>