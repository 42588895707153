import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin, BIcon } from 'bootstrap-vue'
// import titleMixin from './mixins/titleMixin'
import VueMeta from 'vue-meta'

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

// // quick fix leaflet icon marker missing
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/so.scss'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMeta)

// Vue.mixin(titleMixin)

Vue.component('BIcon', BIcon)
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.prototype.$secret = {
  recaptchaSiteKey:'6LfoX5AaAAAAAF5t5DsQBPx9KeUCm1fMg9oseLc1'
}

var endpoint = 'https://ppdb.soalonline.com'
if (Vue.config.productionTip==true) {
  endpoint = 'http://localhost:8787'
}

Vue.prototype.$endpoint = {
  propinsi: endpoint + '/rest-api/propinsi',
  kabupaten:endpoint + '/rest-api/kabupaten',
  kecamatan:endpoint + '/rest-api/kecamatan',
  upload:   endpoint + '/rest-api/upload',
  submit:   endpoint + '/rest-api/submitppdb',
  sekolah:  endpoint + '/rest-api/sekolahppdb',
  detail:   endpoint + '/rest-api/detailppdb',
  list:     endpoint + '/rest-api/result-list',
  result:   endpoint + '/rest-api/result',
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
