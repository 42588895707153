<template>
  <div id="home" class="bg-white">

    <b-container>

      <div class="text-center h4 mt-3 pb-2">Selamat Datang di Sistem PPDB Online</div>
      <hr/>

      <p>Situs ini dipersiapkan sebagai pusat informasi dan pengolahan seleksi data siswa peserta PPDB dalam jaringan seluruh Indonesia secara realtime</p>
      <p>Bagi anda calon peserta, harap membaca Aturan dan Prosedur pendaftaran dengan seksama sebelum melakukan proses pendaftaran. Demikian informasi ini dan terima kasih atas perhatian dan kerjasamanya.</p>

      <hr/>

      <b-list-group>
        <b-list-group-item class="bg-so">Alur Pendaftaran PPDB Online</b-list-group-item>
        <b-list-group-item>Melakukan pendaftaran secara online</b-list-group-item>
        <b-list-group-item>Mencetak surat tanda bukti bukti pendaftaran online</b-list-group-item>
        <b-list-group-item>Datang ke sekolah untuk verifikasi membawa bukti pendaftaran dan kelengkapan berkas PPDB</b-list-group-item>
        <b-list-group-item>Panitia melakukan verifikasi berkas yang diserahkan calon siswa</b-list-group-item>
        <b-list-group-item>Calon siswa menerima tanda bukti verifikasi</b-list-group-item>
        <b-list-group-item>Menunggu pengumuman hasil PPDB</b-list-group-item>
        <b-list-group-item>Calon siswa melakukan daftar ulang ke sekolah</b-list-group-item>
      </b-list-group>

      <div class="m-3 text-center">
        <b-link to="/registration" class="btn btn-so-secondary border rounded">Daftar PPDB Online</b-link>
      </div>

      <p class="border border-info rounded bg-white p-3 mt-3 mb-3 text-center">
          Persiapkan nomor NISN, kartu identitas, kartu keluarga, yang akan dibutuhkan saat pengisian formulir perndaftaran online.
      </p>

    </b-container>

  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Selamat Datang',
  }
}
</script>


<style>

.how-it-work {
	margin-bottom: 60px;
	position: relative;
}
.main-how-it {
	position: absolute;
	top: 217px;
	left: -355px;
	background: #f6f6f6;
	width: 544px;
	height: 110px;
	transform: rotate(-90deg);
	border-radius: 50px;
	box-shadow: 0px 2px 11px -2px rgba(0,0,0,0.4);
}
.main-how-it h4 {
	font-size: 54px;
	padding-top: 20px;
	text-align: center;
	text-transform: uppercase;
	color:#78c043;
	font-weight: 800;
}
.bg-theme {
	color: #008931;
}
.how-it-work .panel {
	border-top-left-radius: 60px;
	border-bottom-left-radius: 60px;
	color:#fff;
	font-size: 16px;
	background: #008931;
	box-shadow: 0px 1px 7px 1px rgba(0,0,0,0.4);
	margin-bottom:15px;
}
.how-it-work .panel span,
.how-it-work .panel:hover span  {
	-webkit-transition: all ease-out 0.3s;
	-moz-transition: all ease-out 0.3s;
	-o-transition: all ease-out 0.3s;
	transition: all ease-out 0.3s;
}
.how-it-work .panel:hover span {
	background: rgba(0,0,0,0.4);
}
.how-it-work .panel:nth-child(even) {
	background: #78c043;
}
.how-it-work .panel-body {
	overflow-y: auto;
	overflow: visible;
	height: 120px;
	padding-left: 120px;
	position: relative;
}
.how-it-work .panel-body span {
	position: absolute;
	top: 15px;
	left: 0px;
	width: 90px;
	height: 90px;
	line-height: 90px;
	color: #008931;
	background: #f6f6f6;
	text-align: center;	
	font-size: 36px;
	font-weight: 800;
	border-radius: 50%;
	box-shadow: 0px 2px 10px rgba(0,0,0,0.4);
}
.how-it-work .panel-body span:before {
	content: "";
	position: absolute;
	top: 35px;
	left: -49px;
	width: 50px;
	height: 20px;
	background: #f6f6f6;
	box-shadow: 0px 8px 3px -5px rgba(0,0,0,0.2);
}
.how-it-work .panel:nth-child(even) span {
	color: #78c043;
}
.how-it-work .step-heading {
	font-size: 28px;
	margin-top: 0px;
	color: rgba(0,0,0,0.3);
	padding-top:15px;
}

</style>