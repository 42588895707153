import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Registration from "../views/Registration"
import Result from "../views/Result"
import Announcement from "../views/Announcement"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/registration',
    name: 'Registrasi',
    component: Registration
  },
  {
    path: '/announcement',
    name: 'Pengumuman',
    component: Announcement
  },
  {
    path: '/result/:code',
    name: 'Hasil',
    component: Result
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
