<template>
  <div id="about">

    <h1>This is an about page</h1>



  </div>
</template>


<script>
export default {
  metaInfo: {
      title: 'Tentang kami',
  },
}
</script>