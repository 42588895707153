<template>
    <div>
        <b-navbar type="dark" class="bg-so">
            <b-navbar-brand href="#">
                <b-img src="./assets/logo_ppdb_v2.png" height="36"/>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item class="text-light" to="/">Home</b-nav-item>
                    <b-nav-item to="/registration">Registrasi</b-nav-item>
                    <b-nav-item to="/announcement">Pengumuman</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto" v-if="Boolean(operatorLink)" @changeOperatorLink="operatorLink=$event">
                    <b-nav-item :href="operatorLink">SoalOnline</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <b-container>
            <router-view />
        </b-container>

        <b-container fluid class="p-3 pt-2 mt-3 bg-so text-light" id="footer">
            <p class="text-center">Powered by <a href="https://soalonline.com" class="text-dark"><strong>SoalOnline.com</strong></a></p>
        </b-container>
    </div>


</template>


<script>
export default {
  // title: 'Selamat Datang : POPDB Online',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Selamat Datang',
    // all titles will be injected into this template
    titleTemplate: '%s | PPDB Online',
    meta: [
      { name: 'description', content: 'Sistem pendaftaran peserta didik baru (PPDB) online, seleksi siswa sekolah berdasarkan empat jalur pendaftaran, zonasi, afirmasi, perpindahan tugas dan prestasi calon siswa.' }
    ]
  },
  data() {
      return {
          operatorLink: null
      }
  }
}
</script>