<template>
    <div id="announcement">
        <div class="row">
            <div class="col-12 border-bottom">
                <div class="h4 text-center  p-3">Hasil Pengumuman PPDB Online</div>
            </div>

            <div v-if="this.list" style="width: 100%;">
                <ul id="list-announcement" class="list-unstyled" >
                    <li v-for="ppdb in this.list" :key="ppdb.code" class="media d.block border-bottom p-2 py-3">
                        <img v-bind:src="ppdb.school_logo ? ppdb.school_logo : 'https://ppdb.soalonline.com/image/default.jpg'" class="mr-3" alt="ppdb.event" width="64" height="64">
                        <div class="media-body d-block">
                            <div class="row p-0">
                                <div class="col-md-9 col-12">
                                    <h6 class="m-0">{{ ppdb.event }}</h6>
                                    <p class="my-0 h7">{{ppdb.address}} </p>
                                </div>
                                <div class="col-md-3 col-12">
                                    <b-button :to="'/result/'+ppdb.code" squared variant="info" size="sm">Pengumuman</b-button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Pengumuman PPDB Online',
  },
  data() {
      return {
          list: null,
      }
  },
  
  mounted() {
    axios
    .post(this.$endpoint.list)
    .then(
        response => {
            this.list = response.data.data
            console.log('list:', this.list)
        }
    )
    .catch(error => {
        alert('Gagal terkoneksi ke server, periksa internet anda, atau refresh browser')
        console.log(error)
    })
  }
}
</script>
