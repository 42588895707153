
<template>
    <div>
        <p class="text-center h4 p-3 border-bottom mb-4">
            Formulir Registrasi PPDB Online
        </p>

        <b-container v-if="state=='INTRO'" class="m-3">
            <b-row>
                <b-col>
                    <div>Sebelum mengisi formulir pendaftaran PPDB, berikut ini hal yang perlu disiapkan untuk memudahkan pengisian berkas online:</div>
                    <ul>
                        <li>Nomor NIK / NISN Siswa</li>
                        <li>Nomor NIK Orang tua siswa</li>
                        <li>Raport belajar siswa</li>
                        <li>Foto diri (close-up) siswa <small>(format jpg, ukuran maks 1MB)</small></li>
                        <li>Foto Kartu identitas atau akta kelahiran siswa <small>(format jpg, ukuran maks 1MB)</small></li>
                        <li>Foto Kartu keluarga orangtua siswa <small>(format jpg, ukuran maks 1MB)</small></li>
                        <li>
                            Khusus untuk jalur pendaftaran Afirmasi, Foto surat keterangan tidak mampu,  <small>(format jpg, ukuran maks 1MB)</small>
                        </li>
                        <li>
                            Khusus untuk jalur pendaftaran Mutasi Orangtua, Foto surat mutasi orang tua siswa,  <small>(format jpg, ukuran maks 1MB)</small>
                        </li>
                        <li>
                            Khusus untuk jalur pendaftaran Prestasi, Foto sertifikat / surat hasil lomba / piagam penghargaan <small>(format jpg, ukuran maks 1MB)</small>
                        </li>
                    </ul>
                    <div class="text-center m-3">
                        <b-button type="button" @click="startForm()" class="btn btn-so-secondary">Ya sudah saya siapkan, mulai isi formulir</b-button>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="state=='FORM'" class="state-form" id="registration">
            <b-row>
                <b-col>
                    <div id="list-registration">
                        <b-form id="registration-form" v-on:submit.prevent="onSubmit" v-if="show" :class="status.submit?'submit':null" >
                            <!-- Step 1 : Pemilihan Sekolah -->
                            <div id="step-1" class="h6 bg-so p-2 text-light">Sekolah tujuan PPDB Online</div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-school-2" label="Propinsi:" label-for="input-school-2">
                                        <b-form-select
                                                id="input-school-2"
                                                v-model="form.schoolPropinsi"
                                                :options="schoolPropinsiList"
                                                required
                                                @change="updateKabupatenSekolah()"
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-school-3" label="Kabupaten:" label-for="input-school-3">
                                        <b-form-select
                                                id="input-school-3"
                                                v-model="form.schoolKabupaten"
                                                :options="schoolKabupatenList"
                                                required
                                                @change="updateKecamatanSekolah()"
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-school-4" label="Kecamatan:" label-for="input-school-4">
                                        <b-form-select
                                                id="input-school-4"
                                                v-model="form.schoolKecamatan"
                                                :options="schoolKecamatanList"
                                                required
                                                @change="updateSekolah()"
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-school-1" label="Jenjang Pendidikan Sekolah:" label-for="input-school-1">
                                        <b-form-select
                                                id="input-school-1"
                                                v-model="form.schoolJenjang"
                                                :options="schoolJenjangList"
                                                @change="updateSekolah()"
                                                required
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-school-5" label="Sekolah:" label-for="input-school-5">
                                        <b-form-select
                                                id="input-school-5"
                                                v-model="form.school"
                                                :options="schoolList"
                                                @change="pickSchool()"
                                                required
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-row v-if="ppdb.id">
                                <b-col>
                                    <table class="table">
                                        <tr>
                                            <td colspan="2" class="p-1 bg-so text-light text-center">
                                                Informasi Sekolah Tujuan
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Sekolah</td>
                                            <td>{{ppdb.school.name}}</td>
                                        </tr>
                                        <tr>
                                            <td>PPDB</td>
                                            <td>{{ppdb.name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Masa pendaftaran</td>
                                            <td>{{ppdb.duration.start}} - {{ppdb.duration.end}}</td>
                                        </tr>
                                        <tr v-if="ppdb.psikotes">
                                            <td>Jadwal Ujian Psikotes</td>
                                            <td>{{ ppdb.tgl_psikotes }}</td>
                                        </tr>
                                        <tr>
                                            <td>Pengumuman seleksi</td>
                                            <td>{{ppdb.duration.complete}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <table id="table-kuota-ppdb">
                                                    <thead>
                                                    <tr>
                                                        <th>Kuota Total</th>
                                                        <th>Zonasi</th>
                                                        <th>Afirmasi</th>
                                                        <th>Prestasi</th>
                                                        <th>Mutasi</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>{{ppdb.kuota.total}}</td>
                                                        <td>{{ppdb.kuota.zonasi}}</td>
                                                        <td>{{ppdb.kuota.afirmasi}}</td>
                                                        <td>{{ppdb.kuota.prestasi}}</td>
                                                        <td>{{ppdb.kuota.pindah_orangtua}}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr v-if="ppdb.description">
                                            <td colspan="2">
                                                <p class="bg-so text-center text-light d-none">Deskripsi PPDB</p>
                                                <span v-html="ppdb.description"></span>
                                            </td>
                                        </tr>
                                        <tr v-if="ppdb.banner_url"> 
                                            <td class="text-center" colspan="2">
                                                <img class="img-fluid" :src="ppdb.banner_url" />
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>

                            <!-- end of step 1 -->
                            <!-- Step 2 : Informai Siswa -->
                            <div id="step-2" class="h6 bg-so p-2 text-light">Informasi calon siswa</div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-1" label="Nama lengkap calon siswa:" label-for="input-1">
                                        <b-form-input
                                                id="input-1"
                                                v-model="form.userNamalengkap"
                                                placeholder="Ketik nama lengkap"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-13" label="Nomor Identitas /NIK siswa:" label-for="input-13">
                                        <b-form-input
                                                id="input-13"
                                                v-model="form.userNik"
                                                placeholder="Ketik NIK siswa"
                                                required
                                                type="number"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-14" label="Nomor Induk Siswa Nasional (NISN):" label-for="input-14" description="Khusus PPDB SD masukkan NIK calon siswa" >
                                        <b-form-input
                                                id="input-14"
                                                v-model="form.userNisn"
                                                placeholder="Ketik NISN"
                                                :required="form.schoolJenjang!='1'"
                                                type="number"
                                                :state="nisnState"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-15" label="Sekolah Asal:" label-for="input-15" >
                                        <b-form-input
                                                id="input-15"
                                                v-model="form.userAsalSekolah"
                                                placeholder="Ketik nama sekolah asal"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>



                            <div class="row">
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-2" label="Alamat email:" label-for="input-2" description="Email hanya akan dibagikan kepada pihak sekolah saja." >
                                        <b-form-input
                                                id="input-2"
                                                v-model="form.userEmail"
                                                type="email"
                                                placeholder="Ketik email"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm-6">
                                    <b-form-group id="input-group-3" label="Nomor Telp./HP:" label-for="input-3"  >
                                        <b-form-input
                                                id="input-3"
                                                v-model="form.userPhone"
                                                type="tel"
                                                placeholder="Ketik nomor telp/hp"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-4" label="Tempat lahir:" label-for="input-4"  >
                                        <b-form-input
                                                id="input-4"
                                                v-model="form.userTempatLahir"
                                                placeholder="Ketik kota tempat lahir"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-5" label="Tanggal lahir:" label-for="input-5"  >
                                        <dropdown-datepicker
                                                v-model="form.userTglLahir"
                                                dropdownClass="form-control col-4 d-inline"
                                                displayFormat="dmy"
                                                submitFormat="yyyy-mm-dd"
                                                dayLabel="-Tgl-"
                                                monthLabel="-Bulan-"
                                                yearLabel="-Tahun-"
                                                :maxDate="ppdb.limit_lahir"
                                                :maxAge="17"
                                                :allowFuture="false"
                                                :required="true"
                                                :daySuffixes="false"
                                                :monthSuffixes="false"
                                                :monthLongValues="monthList"
                                        ></dropdown-datepicker>
                                        <small v-if="ppdb.limit_lahir_text" class="form-text text-muted">Batas tgl lahir : {{ppdb.limit_lahir_text}}</small>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-6" label="Jenis kelamin:" label-for="input-6">
                                        <b-form-select
                                                id="input-6"
                                                v-model="form.userGender"
                                                :options="userGenderList"
                                                required
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-7" label="Agama:" label-for="input-7">
                                        <b-form-select
                                                id="input-7"
                                                v-model="form.userAgama"
                                                :options="userAgamaList"
                                                required
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-group id="input-group-8" label="Alamat Rumah:" label-for="input-8">
                                <b-form-input
                                        id="input-8"
                                        v-model="form.userAlamat"
                                        placeholder="Ketik nama jalan, RT, RW dan nomor rumah"
                                        rows="4"
                                        required
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group id="input-group-9" label="Propinsi:" label-for="input-9">
                                <b-form-select
                                        id="input-9"
                                        v-model="form.userPropinsi"
                                        :options="userPropinsiList"
                                        required
                                        @change="updateKabupaten()"
                                ></b-form-select>
                            </b-form-group>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-10" label="Kabupaten:" label-for="input-10">
                                        <b-form-select
                                                id="input-10"
                                                v-model="form.userKabupaten"
                                                :options="userKabupatenList"
                                                required
                                                @change="updateKecamatan()"
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-11" label="Kecamatan:" label-for="input-11">
                                        <b-form-select
                                                id="input-11"
                                                v-model="form.userKecamatan"
                                                :options="userKecamatanList"
                                                required
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-12" label="Kelurahan:" label-for="input-12">
                                        <b-form-input
                                                id="input-12"
                                                v-model="form.userKelurahan"
                                                placeholder="Ketik kelurahan"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-13" label="Kodepos:" label-for="input-13">
                                        <b-form-input
                                                id="input-13"
                                                v-model="form.userKodepos"
                                                type="number"
                                                placeholder="Ketik kodepos"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-container class="border rounded p-2 mt-2 mb-2 text-center">
                                <p>
                                    Peta lokasi rumah<br/>
                                    <small><span class="text-danger">Pindahkan penanda pada peta ke lokasi rumah calon siswa untuk menentukan jarak dengan sekolah</span></small>
                                </p>

                                <l-map
                                    ref="myMap"
                                    :zoom="mapSetup.zoom"
                                    :center="mapSetup.center"
                                    :options="mapSetup.mapOptions"
                                    style="height: 50vmin;"
                                    @ready="findloc"
                                    @click="setMarker"
                                    >
                                    <l-tile-layer
                                        :url="mapSetup.url"
                                        :attribution="mapSetup.attribution"
                                    />
                                    <l-polyline v-if="mapSetup.zonePath" :lat-lngs="mapSetup.zonePath" color="#FF5733"></l-polyline>

                                    <l-marker v-if="mapSetup.ppdbLoc" :lat-lng="mapSetup.ppdbLoc" :icon="mapSetup.icon">
                                        <l-tooltip :options="{ interactive: true }">
                                            <div>
                                                Lokasi Sekolah
                                                <p v-show="Boolean(ppdb.school.name)">{{ ppdb.school.name }}</p>
                                            </div>
                                        </l-tooltip>
                                    </l-marker>
                                    <l-marker v-if="mapSetup.userLoc" ref="marker" :lat-lng="mapSetup.userLoc" :options="{autoPan: true}" :draggable="true" @dragend="updateMarker">
                                        <l-tooltip :options="{ permanent: true, interactive: true }">
                                            <div>
                                                Lokasi Rumah
                                                <p v-show="Boolean(regData.userAlamat)">{{ regData.userAlamat }}</p>
                                            </div>
                                        </l-tooltip>
                                    </l-marker>
                                </l-map>


                                <b-row>
                                    <b-col class="text-center">
                                        <b-button type="button" variant="success" class="mt-2" @click="findloc">
                                            <b-icon-geo-alt></b-icon-geo-alt> Deteksi Lokasi
                                        </b-button>
                                        &nbsp;
                                        <b-button type="button" variant="info" class="mt-2" @click="panMap">Auto Zoom</b-button>
                                        <span class="p-2 d-none">Jarak lokasi : {{ Math.floor(Number(mapSetup.distancePath)).toLocaleString('id-ID') }} meter</span>
                                    </b-col>
                                    <b-col sm="4" cols="6" class="d-none">
                                        <b-row class="mt-2">
                                            <b-col cols=6><b-form-input v-if="mapSetup.marker" v-model="mapSetup.marker.lat" type="number" required readonly /></b-col>
                                            <b-col cols=6><b-form-input v-if="mapSetup.marker" v-model="mapSetup.marker.lng" type="number" required readonly /></b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <p class="d-none">Lokasi titik : {{ mapSetup.marker }}</p>


                            </b-container>
                            <!-- end of step 2 -->

                            <div id="step-7" class="h6 bg-so p-2 text-light" v-if="ppdb.ukuran_baju">Ukuran baju seragam siswa</div>
                            <div class="row"  v-if="ppdb.ukuran_baju">
                                <div class="col-sm">
                                    <b-form-group id="input-group-29" label="Ukuran Baju Seragam:" label-for="input-29">
                                        <b-form-select
                                                v-model="form.userUkuranBaju"
                                                placeholder="Pilih ukuran baju"
                                                :options="ukuranBajuList"
                                                required>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-img
                                            src="https://socdn.soalonline.com/ppdb/images/ukuran-seragam-batik-sekolah.jpg"
                                            fluid-grow>
                                    </b-img>
                                </div>
                            </div>

                            <!-- Step 3 : Informasi Orangtua -->
                            <div id="step-3" class="h6 bg-so p-2 text-light">Informasi orang tua calon siswa</div>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-1" label="Nama ayah:" label-for="input-ortu-1" >
                                        <b-form-input
                                                id="input-ortu-1"
                                                v-model="form.ortuNamaAyah"
                                                placeholder="Ketik nama lengkap ayah"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-3" label="Nama ibu:" label-for="input-ortu-3">
                                        <b-form-input
                                                id="input-ortu-3"
                                                v-model="form.ortuNamaIbu"
                                                placeholder="Ketik nama lengkap ibu"
                                                required
                                        ></b-form-input>
                                    </b-form-group>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-11" label="NIK ayah:" label-for="input-ortu-11" >
                                        <b-form-input
                                                id="input-ortu-11"
                                                v-model="form.ortuNIKAyah"
                                                placeholder="Ketik nomor identitas ayah"
                                                type="number"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-12" label="NIK ibu:" label-for="input-ortu-12" >
                                        <b-form-input
                                                id="input-ortu-12"
                                                v-model="form.ortuNIKIbu"
                                                placeholder="Ketik nomor identitas ibu"
                                                type="number"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-2" label="Pekerjaan ayah:" label-for="input-ortu-2" >
                                        <b-form-input
                                                id="input-ortu-2"
                                                v-model="form.ortuJobAyah"
                                                placeholder="Ketik pekerjaan ayah"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-4" label="Pekerjaan ibu:" label-for="input-ortu-4" >
                                        <b-form-input
                                                id="input-ortu-4"
                                                v-model="form.ortuJobIbu"
                                                placeholder="Ketik pekerjaan ibu"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-group id="input-group-5" label="Alamat rumah orangtua:" label-for="input-ortu-5">
                                <b-form-input
                                        id="input-ortu-5"
                                        v-model="form.ortuAlamat"
                                        placeholder="Ketik nama jalan, RT, RW dan nomor rumah"
                                        rows="4"
                                        required
                                ></b-form-input>
                            </b-form-group>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-6" label="Propinsi:" label-for="input-ortu-6">
                                        <b-form-select
                                                id="input-ortu-6"
                                                v-model="form.ortuPropinsi"
                                                :options="ortuPropinsiList"
                                                required
                                                @change="updateKabupatenOrangtua()"
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-7" label="Kabupaten:" label-for="input-ortu-7">
                                        <b-form-select
                                                id="input-ortu-7"
                                                v-model="form.ortuKabupaten"
                                                :options="ortuKabupatenList"
                                                required
                                                @change="updateKecamatanOrangtua()"
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-8" label="Kecamatan:" label-for="input-ortu-8">
                                        <b-form-select
                                                id="input-ortu-8"
                                                v-model="form.ortuKecamatan"
                                                :options="ortuKecamatanList"
                                                required
                                        ></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group id="input-group-9" label="Kelurahan:" label-for="input-ortu-9">
                                        <b-form-input
                                                id="input-ortu-9"
                                                v-model="form.ortuKelurahan"
                                                placeholder="Ketik kelurahan"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <b-form-group id="input-group-10" label="Kodepos:" label-for="input-ortu-10">
                                        <b-form-input
                                                id="input-ortu-10"
                                                v-model="form.ortuKodepos"
                                                placeholder="Ketik kodepos"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm">
                                    <b-form-group label="No Telepon Orangtua:" >
                                        <b-form-input
                                                v-model="form.ortuPhone"
                                                placeholder="Ketik nomor telp/hp"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <!-- end of step 3 -->

                            <!-- Step 4 : Upload nilai -->
                            <div v-if="ppdb.upload_nilai">
                                <div id="step-3b" class="h6 bg-so p-2 text-light">Nilai Raport Siswa</div>
                                <table class="table table-striped table-sm" id="table-nilai">
                                    <thead>
                                    <tr>
                                        <th rowspan="2" class="col-sm-7">Mata Pelajaran</th>
                                        <th colspan="2">Kelas 4</th>
                                        <th colspan="2">Kelas 5</th>
                                        <th>Kelas 6</th>
                                    </tr>
                                    <tr>
                                        <th class="col-sm-1"  v-if="ppdb.upload_nilai_data.periode.includes('k_4_1')">Semester I</th>
                                        <th class="col-sm-1"  v-if="ppdb.upload_nilai_data.periode.includes('k_4_2')">Semester II</th>
                                        <th class="col-sm-1"  v-if="ppdb.upload_nilai_data.periode.includes('k_5_1')">Semester I</th>
                                        <th class="col-sm-1"  v-if="ppdb.upload_nilai_data.periode.includes('k_5_2')">Semester II</th>
                                        <th class="col-sm-1"  v-if="ppdb.upload_nilai_data.periode.includes('k_6_1')">Semester I</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="ppdb.upload_nilai_data.mapel.includes('mtk')">
                                        <td >Matematika</td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_1')"><b-form-input size="sm" v-model="form.nilai.mtk.k_4_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_2')"><b-form-input size="sm" v-model="form.nilai.mtk.k_4_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_1')"><b-form-input size="sm" v-model="form.nilai.mtk.k_5_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_2')"><b-form-input size="sm" v-model="form.nilai.mtk.k_5_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_6_1')"><b-form-input size="sm" v-model="form.nilai.mtk.k_6_1"></b-form-input></td>
                                    </tr>
                                    <tr v-if="ppdb.upload_nilai_data.mapel.includes('ipa')">
                                        <td>Ilmu Pengetahuan Alam</td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_1')"><b-form-input size="sm" v-model="form.nilai.ipa.k_4_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_2')"><b-form-input size="sm" v-model="form.nilai.ipa.k_4_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_1')"><b-form-input size="sm" v-model="form.nilai.ipa.k_5_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_2')"><b-form-input size="sm" v-model="form.nilai.ipa.k_5_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_6_1')"><b-form-input size="sm" v-model="form.nilai.ipa.k_6_1"></b-form-input></td>
                                    </tr>
                                    <tr v-if="ppdb.upload_nilai_data.mapel.includes('ips')">
                                        <td>Ilmu Pengetahuan Sosial</td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_1')"><b-form-input size="sm" v-model="form.nilai.ips.k_4_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_2')"><b-form-input size="sm" v-model="form.nilai.ips.k_4_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_1')"><b-form-input size="sm" v-model="form.nilai.ips.k_5_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_2')"><b-form-input size="sm" v-model="form.nilai.ips.k_5_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_6_1')"><b-form-input size="sm" v-model="form.nilai.ips.k_6_1"></b-form-input></td>
                                    </tr>
                                    <tr v-if="ppdb.upload_nilai_data.mapel.includes('indonesia')">
                                        <td>Bahasa Indonesia</td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_1')"><b-form-input size="sm" v-model="form.nilai.indonesia.k_4_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_2')"><b-form-input size="sm" v-model="form.nilai.indonesia.k_4_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_1')"><b-form-input size="sm" v-model="form.nilai.indonesia.k_5_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_2')"><b-form-input size="sm" v-model="form.nilai.indonesia.k_5_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_6_1')"><b-form-input size="sm" v-model="form.nilai.indonesia.k_6_1"></b-form-input></td>
                                    </tr>
                                    <tr v-if="ppdb.upload_nilai_data.mapel.includes('pkn')">
                                        <td>Pendidikan Kewarganegaraan</td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_1')"><b-form-input size="sm" v-model="form.nilai.pkn.k_4_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_4_2')"><b-form-input size="sm" v-model="form.nilai.pkn.k_4_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_1')"><b-form-input size="sm" v-model="form.nilai.pkn.k_5_1"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_5_2')"><b-form-input size="sm" v-model="form.nilai.pkn.k_5_2"></b-form-input></td>
                                        <td v-if="ppdb.upload_nilai_data.periode.includes('k_6_1')"><b-form-input size="sm" v-model="form.nilai.pkn.k_6_1"></b-form-input></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Step 4 : Jalur pendaftaran -->
                            <div id="step-4" class="h6 bg-so p-2 text-light">Pilihan jalur pendaftaran dan dokumen</div>
                            <b-form-group id="input-group-1" label="Jalur pendaftaran PPDB:" label-for="input-jalur-1">
                                <b-form-select
                                        id="input-jalur-1"
                                        v-model="form.jalurPendaftaran"
                                        :options="jalurPendaftaranList"
                                        required
                                ></b-form-select>
                            </b-form-group>
                            <b-card class="mb-3">
                                <b-media class="border-bottom pb-2 mb-2">
                                    <p class="mb-0">
                                        <strong>Jalur Zonasi</strong> ditetapkan berdasarkan domisili peserta didik dan sekolah, yang dibuktikan lewat alamat pada kartu keluarga yang diterbitkan paling singkat satu tahun sejak pendaftaran.
                                    </p>
                                </b-media>
                                <b-media class="border-bottom pb-2 mb-2">
                                    <p class="mb-0">
                                        <strong>Jalur Afirmasi</strong> diperuntukkan bagi siswa dari keluarga ekonomi tidak mampu. Hal ini dibuktikan melalui keikutsertaan siswa dalam program keluarga tidak mampu dari pemerintah.                                    </p>
                                </b-media>
                                <b-media class="border-bottom pb-2 mb-2">
                                    <p class="mb-0">
                                        <strong>Jalur Prestasi</strong> dapat ditempuh menggunakan nilai ujian sekolah atau ujian nasional, dan hasil perlombaan atau penghargaan di bidang akademik atau non akademik.
                                    </p>
                                </b-media>
                                <b-media>
                                    <p class="mb-0">
                                        <strong>Jalur Perpindahan Tugas Orang Tua atau Wali</strong> diperuntukkan bagi calon peserta didik yang mengikuti perpindahan tugas orang tua atau wali dengan menunjukkan bukti surat penugasan orang tua atau wali.
                                    </p>
                                </b-media>
                            </b-card>
                            <!-- end of step 4 -->

                            <!-- Step 5 : Berkas pendaftaran -->
                            <div id="step-5" class="h6 bg-so p-2 text-light">Berkas pendaftaran</div>
                            <b-row class="border-bottom mb-2">
                                <b-col cols="12">
                                    Foto diri siswa:
                                </b-col>
                                <b-col cols="10">
                                    <b-form-file
                                            v-model="file.photo"
                                            placeholder="Pilih file/foto diri siswa"
                                            drop-placeholder="Letakkan file disini..."
                                            browseText="Pilih"
                                            accept=".jpg, .jpeg"
                                            :disabled="status.submit"
                                            :state="Boolean(file.photo) && file.photo.size<=1024*1024 && Boolean(form.file.photo)"
                                            @change="resetFile('photo')"
                                            required
                                    >
                                    </b-form-file>
                                    <div class="text-right"><small>*.jpg, *.jpeg, maksimum 1MB</small></div>
                                </b-col>
                                <b-col cols="2" >
                                    <b-button v-if="!Boolean(form.file.photo) && !fileSubmit.photo" type="button"  @click="upload2Cloud('photo')" variant="info" size="sm"><b-icon-upload/> Unggah</b-button>
                                    <b-icon v-if="fileSubmit.photo" icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
                                    <b-button  v-if="Boolean(form.file.photo)" type="button" variant="success" ><b-icon-check/></b-button>
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom mb-2">
                                <b-col cols="12">
                                    Foto akta lahir siswa:
                                </b-col>
                                <b-col cols="10">
                                    <b-form-file
                                            v-model="file.aktaLahir"
                                            placeholder="Pilih file/foto akta lahir siswa"
                                            drop-placeholder="Letakkan file disini..."
                                            browseText="Pilih"
                                            accept=".jpg, .jpeg"
                                            :disabled="status.submit"
                                            :state="Boolean(file.aktaLahir) && file.aktaLahir.size<=1024*1024 && Boolean(form.file.aktaLahir)"
                                            @change="resetFile('aktaLahir')"
                                            required
                                    >
                                    </b-form-file>
                                    <div class="text-right"><small>.jpg, .jpeg, maksimum 1MB</small></div>
                                </b-col>
                                <b-col cols="2" >
                                    <b-button v-if="!Boolean(form.file.aktaLahir) && !fileSubmit.aktaLahir" type="button"  @click="upload2Cloud('aktaLahir')" variant="info" size="sm"><b-icon-upload/> Unggah</b-button>
                                    <b-icon v-if="fileSubmit.aktaLahir" icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
                                    <b-button  v-if="Boolean(form.file.aktaLahir)" type="button" variant="success" ><b-icon-check/></b-button>
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom mb-2">
                                <b-col cols="12">
                                    Foto kartu keluarga siswa:
                                </b-col>
                                <b-col cols="10">
                                    <b-form-file
                                            v-model="file.kk"
                                            placeholder="Pilih file/foto kartu keluarga"
                                            drop-placeholder="Letakkan file disini..."
                                            browseText="Pilih"
                                            accept=".jpg, .jpeg"
                                            :disabled="status.submit"
                                            :state="Boolean(file.kk) && file.kk.size<=1024*1024 && Boolean(form.file.kk)"
                                            @change="resetFile('kk')"
                                            required
                                    >
                                    </b-form-file>
                                    <div class="text-right"><small>.jpg, .jpeg, maksimum 1MB</small></div>
                                </b-col>
                                <b-col cols="2" >
                                    <b-button v-if="!Boolean(form.file.kk) && !fileSubmit.kk" type="button"  @click="upload2Cloud('kk')" variant="info" size="sm"><b-icon-upload/> Unggah</b-button>
                                    <b-icon v-if="fileSubmit.kk" icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
                                    <b-button  v-if="Boolean(form.file.kk)" type="button" variant="success" ><b-icon-check/></b-button>
                                </b-col>
                            </b-row>

                            <div v-if="form.jalurPendaftaran=='AFIRMASI'">
                                <div class="col-xs-12 bg-so p-2 text-light">Berkas pendaftaran jalur AFIRMASI</div>

                                <b-row class="border-bottom mb-2">
                                    <b-col cols="12">
                                        Foto kartu keterangan tidak mampu::
                                    </b-col>
                                    <b-col cols="10">
                                        <b-form-file
                                                v-model="file.kms"
                                                placeholder="Pilih file/foto"
                                                drop-placeholder="Letakkan file disini..."
                                                browseText="Pilih"
                                                accept=".jpg, .jpeg"
                                                :disabled="status.submit"
                                                :state="Boolean(file.kms) && file.kms.size<=1024*1024 && Boolean(form.file.kms)"
                                                @change="resetFile('kms')"
                                                required
                                        >
                                        </b-form-file>
                                        <div class="text-right"><small>.jpg, .jpeg, maksimum 1MB</small></div>
                                    </b-col>
                                    <b-col cols="2" >
                                        <b-button v-if="!Boolean(form.file.kms) && !fileSubmit.kms" type="button"  @click="upload2Cloud('kms')" variant="info" size="sm"><b-icon-upload/> Unggah</b-button>
                                        <b-icon v-if="fileSubmit.kms" icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
                                        <b-button  v-if="Boolean(form.file.kms)" type="button" variant="success" ><b-icon-check/></b-button>
                                    </b-col>
                                </b-row>
                            </div>

                            <div v-if="form.jalurPendaftaran=='ORTU_PINDAH'">
                                <div class="col-xs-12 bg-so p-2 text-light">Berkas pendaftaran jalur MUTASI</div>

                                <b-row class="border-bottom mb-2">
                                    <b-col cols="12">
                                        Foto surat keterangan mutasi:
                                    </b-col>
                                    <b-col cols="10">
                                        <b-form-file
                                                v-model="file.mutasi"
                                                placeholder="Pilih file/foto"
                                                drop-placeholder="Letakkan file disini..."
                                                browseText="Pilih"
                                                accept=".jpg, .jpeg"
                                                :disabled="status.submit"
                                                :state="Boolean(file.mutasi) && file.mutasi.size<=1024*1024 && Boolean(form.file.mutasi)"
                                                @change="resetFile('mutasi')"
                                                required
                                        >
                                        </b-form-file>
                                        <div class="text-right"><small>.jpg, .jpeg, maksimum 1MB</small></div>
                                    </b-col>
                                    <b-col cols="2" >
                                        <b-button v-if="!Boolean(form.file.mutasi) && !fileSubmit.mutasi" type="button"  @click="upload2Cloud('mutasi')" variant="info" size="sm"><b-icon-upload/> Unggah</b-button>
                                        <b-icon v-if="fileSubmit.mutasi" icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
                                        <b-button  v-if="Boolean(form.file.mutasi)" type="button" variant="success" ><b-icon-check/></b-button>
                                    </b-col>
                                </b-row>

                            </div>

                            <div v-if="form.jalurPendaftaran=='PRESTASI'">
                                <div class="col-xs-12 bg-so p-2 text-light">Berkas pendaftaran jalur PRESTASI</div>
                                <p class="mt-2 mb-2 border-bottom pb-2">Upload file sertifikat, penghargaan, atau prestasi lainnya setingkat kabupaten & nasional</p>
                                <b-row v-for="(itemFile, index) in file.prestasi" :key="index" class="border-bottom mb-2">
                                    <b-col cols="5">
                                        <b-form-input
                                                v-model="itemFile.judul"
                                                placeholder="Nama/keterangan prestasi"
                                                required
                                                :state="Boolean(itemFile.judul)"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col cols="5">
                                        <b-form-file
                                                :data-id="index"
                                                v-model="itemFile.file"
                                                placeholder="Pilih file/foto piagam/sertifikat"
                                                drop-placeholder="Letakkan file disini..."
                                                browseText="Pilih"
                                                accept=".jpg, .jpeg"
                                                :disabled="status.submit"
                                                :state="Boolean(itemFile.file) && itemFile.file.size<=1024*1024"
                                                @change="resetFilePrestasi(index)"
                                                required
                                        >
                                        </b-form-file>
                                        <div class="text-right"><small>.jpg, .jpeg, maksimum 1MB</small></div>
                                    </b-col>
                                    <b-col cols="2" >
                                        <b-button v-if="!Boolean(itemFile.filename) && !itemFile.isSubmit" type="button"  @click="upload2CloudPrestasi(index)" variant="info" size="sm"><b-icon-upload/> Unggah</b-button>
                                        <b-icon v-if="itemFile.isSubmit" icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
                                        <b-button  v-if="Boolean(itemFile.filename)" type="button" variant="success" ><b-icon-check/></b-button>

                                        <b-button v-if="index>0" type="button" @click="deleteFilePrestasi(index)" :data-id="index" variant="danger" size="sm"><b-icon-trash/></b-button>
                                    </b-col>
                                </b-row>

                                <b-button type="button" variant="warning" :block="true" class="mb-2" @click="tambahFilePrestasi()">Tambah file</b-button>
                            </div>

                            <!-- end of step 5 -->

                            <!-- start of step 6 : Persetujuan-->
                            <div id="step-6" class="h6 bg-so p-2 text-light">Persetujuan pendaftaran</div>

                            <p class="border border-success bg-light p-3 text-center">Dengan menekan tombol Kirim Formulir, saya menyatakan bahwa data yang saya isikan adalah sebenarnya dan apabila ditemukan ketidakcocokan data, saya akan menerima keputusan sekolah untuk men-diskualifikasi pendaftaran saya.</p>

                            <b-container class="text-center" id="recaptcha-container">
                                <vue-recaptcha
                                        ref="recaptcha"
                                        @verify="onVerify"
                                        @expired="onExpired"
                                        :sitekey="this.$secret.recaptchaSiteKey"
                                        :loadRecaptchaScript="true"
                                        required
                                >
                                </vue-recaptcha>
                            </b-container>

                            <div class="text-danger  bg-danger-light border rounded border-danger p-2 m-2" v-if="errors">
                                {{ errors }}
                            </div>

                            <b-container class="text-center mt-3 border-top pt-2">
                                <b-row>
                                    <b-col class="text-center">
                                        <b-button class="d-none" type="submit" variant="warning" @click="logForm()">form-data</b-button>
                                        <b-button type="submit" class="btn btn-danger">Kirim formulir <b-icon-cloud-arrow-up /></b-button>
                                    </b-col>
                                </b-row>
                            </b-container>

                            <b-card class="mt-3 d-none" header="Form Data Result">
                                <pre class="m-0">{{ form }}</pre>
                            </b-card>

                            <b-container class="mt-3 mb-4 text-danger bg-danger-light border rounded border-danger p-3" >
                                <p class="text-center">Isilah data formulir di atas dengan benar dan sejujurnya, pihak sekolah akan memverifikasi data dan berkas yang anda isi pada formulir ini.</p>
                                <p class="text-center">Jika ditemukan ada ketidakcocokan data, atau data yang diberikan tidak sebenarnya, maka pihak sekolah berhak untuk mendiskualifikas pendaftaran siswa dimaksud.</p>
                                <p class="text-center"><strong>KEPUTUSAN SEKOLAH BERSIFAT MUTLAK DAN TIDAK DAPAT DIGUGAT</strong></p>
                            </b-container>

                            <!-- end of step 6 -->

                        </b-form>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="state=='SUBMIT'" class="state-submit" id="submit">
            <b-row>
                <b-col class="text-center m-2">
                    <h5 class="text-center">Mengirim berkas formulir PPDB ke server</h5>
                    <br/>
                    <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                    <br/>
                    <div class="text-danger m-2 mt-3">
                        Harap untuk tidak melakukan refresh browser, selama proses berlangsung.
                    </div>
                    <br/>

                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="state=='SUCCESS'" class="state-success" id="success">
            <b-row>
                <b-col>
                    <div v-if="submitError" class="bg-danger-light border border-danger p-3 m-2 text-center" >
                        {{ submitError }}
                    </div>
                    <table id="registration-summary" ref="ppdbSummary" align="center">
                        <tbody>
                            <tr>
                                <td colspan="2" class="h5 text-center"><strong>TANDA BUKTI PENDAFTARAN PPDB</strong></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="p-3">&nbsp;</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="border border-dark text-center p-3 m-3 h5">
                                    Nomor Pendaftaran : <span id="kode-reg">{{ regData.no_urut }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="p-3">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Kode Registrasi</td>
                                <td>{{ regData.kode_reg}}</td>
                            </tr>
                            <tr>
                                <td>Nama Lengkap</td>
                                <td>{{ regData.userNamalengkap}}</td>
                            </tr>
                            <tr>
                                <td>NIK</td>
                                <td>{{ regData.userNik}}</td>
                            </tr>
                            <tr>
                                <td>NISN</td>
                                <td>{{ regData.userNisn}}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{{ regData.userEmail }}</td>
                            </tr>
                            <tr>
                                <td>No telepon</td>
                                <td>{{ regData.userPhone }}</td>
                            </tr>
                            <tr>
                                <td colspan="2"><hr/></td>
                            </tr>
                            <tr>
                                <td>Sekolah tujuan</td>
                                <td>{{ regData.schoolName }}</td>
                            </tr>
                            <tr>
                                <td>Jalur pendaftaran</td>
                                <td>{{ regData.jalurPendaftaran }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal pendaftaran</td>
                                <td>{{ regData.created }}</td>
                            </tr>
                            <tr>
                                <td colspan="2"><hr/></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-center">Surat ini harap ditunjukkan saat proses verifikasi berkas di sekolah.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="text-center mt-2" v-if="Boolean(regData.kode_reg)">
                        <b-link :href="regData.pdfUrl" class="btn btn-success m-4" target="_blank">Unduh Dokumen Bukti Registrasi (pdf)</b-link>
                    </div>
                    <div v-if="regData.docSyarat" class="text-center mt-2">
                        <b-link :href="regData.docSyarat" class="btn btn-info m-4" target="_blank">Unduh Formulir Persyaratan PPDB (pdf)</b-link>
                        <br/>
                        <small><strong>Formulir persyaratan wajib diisi</strong> dan diserahkan ketika proses vefifikasi data ke sekolah</small>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from 'axios'
    import VueRecaptcha from 'vue-recaptcha'
    import DropdownDatepicker from 'vue-dropdown-datepicker'
    import { jsPDF } from "jspdf"

    import { latLng, latLngBounds, icon } from "leaflet";
    import { LMap, LTileLayer, LMarker, LTooltip, LPolyline } from "vue2-leaflet"

    export default {
        metaInfo: {
            title: 'Registrasi Peserta',
        },
        components: { 
            VueRecaptcha, 
            DropdownDatepicker,
            LMap,
            LTileLayer,
            LMarker,
            LPolyline,
            LTooltip,
        },
        data() {
            return {
                state: 'INTRO', // 'INTRO', 'FORM', 'SUBMIT', 'SUCCESS', 'ERROR'
                mapSetup: {
                    zoom: 13,
                    center: latLng(47.41322, -1.219482),
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    userLoc: null,
                    ppdbLoc: null,
                    zonePath: null,
                    distancePath: 0,
                    showParagraph: false,
                    mapOptions: {
                        zoomSnap: 0.5
                    },
                    icon: new icon ({
                        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
                        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41]
                    })
                },
                errors: null,
                submitError: null,
                regData: {},
                //     no_urut: null,
                //     kode_reg: null,
                //     created: null,
                //     schoolPropinsi: null,
                //     schoolKabupaten: null,
                //     schoolKecamatan: null,
                //     schoolJenjang: null,
                //     schoolJenjangText: null,
                //     school: null,
                //     userNisn: null,
                //     userNamalengkap: null,
                //     userEmail: null,
                //     userPhone: null,
                //     userPropinsi: null,
                //     userKabupaten: null,
                //     userKecamatan: null,
                //     userKelurahan: null,
                //     userAlamat: null,
                //     userKodepos: null,
                //     userLocLat: null,
                //     userLocLng: null,
                //     userAgama: null,
                //     userNik: null,
                //     userAsalSekolah: null,
                //     userGender: null,
                //     userTempatLahir: null,
                //     userTglLahir: null,
                //     userPhoto: null,
                //     userUkuranBaju: null,
                //     ortuNamaAyah: null,
                //     ortuJobAyah: null,
                //     ortuNamaIbu: null,
                //     ortuJobIbu: null,
                //     ortuAlamat: null,
                //     ortuPropinsi: null,
                //     ortuKabupaten: null,
                //     ortuKecamatan: null,
                //     ortuKelurahan: null,
                //     ortuKodepos: null,
                //     ortuPhone: null,
                //     ortuNIKAyah: null,
                //     ortuNIKIbu: null,
                //     jalurPendaftaran: null,
                //     pdfUrl: null,
                //     docSyarat: null,
                // },
                ppdb: {
                    id: null,
                    name: null,
                    ppdb_name: null,
                    description: null,
                    duration: {
                        start: '1990-01-01',
                        end: '1990-01-01',
                        complete: '1990-01-01'
                    },
                    school: {
                        id: null,
                        code: null,
                        name: null,
                        maps: {
                            lat: null,
                            lng: null
                        }
                    },
                    kuota: {
                        total: 0,
                        zonasi: 0,
                        afirmasi: 0,
                        prestasi: 0,
                        pindah_orangtua: 0
                    },
                    limit_lahir: "1990-01-01",
                    limit_lahir_text: null,
                    upload_nilai: false,
                    upload_nilai_data: {
                        mapel: [],
                        periode: []
                    },
                    psikotes: false,
                    tgl_psikotes: null,
                    ukuran_baju: false,
                    logo_sekolah_url: null,
                    banner_url: null
                },

                // start dummy data for testing
                // form: {
                //     schoolPropinsi: '25823',
                //     schoolKabupaten: '320',
                //     schoolKecamatan: '4557',
                //     schoolJenjang: '2',
                //     school: '1',
                
                //     userNamalengkap: 'abdul',
                //     userEmail: 'abdul@so.com',
                //     userPhone: '085643195083',
                //     userPropinsi: '25823',
                //     userKabupaten: '320',
                //     userKecamatan: '4557',
                //     userKelurahan: 'jatibening',
                //     userAlamat: 'jalan pemuda no 99',
                //     userKodepos: '13000',
                //     userLocLat: null,
                //     userLocLng: null,
                //     userAgama: 'ISLAM',
                //     userNisn: '999999',
                //     userNik: '888888',
                //     userAsalSekolah: 'sd nugraha',
                //     userGender: 'LAKI_LAKI',
                //     userTempatLahir: 'jakarta',
                //     userTglLahir: '1980-06-15',
                
                //     ortuNamaAyah: 'ayah',
                //     ortuJobAyah: 'pns',
                //     ortuNamaIbu: 'ibu',
                //     ortuJobIbu: 'art',
                //     ortuAlamat: 'jl pemuda no 99',
                //     ortuPropinsi: '25823',
                //     ortuKabupaten: '320',
                //     ortuKecamatan: '4557',
                //     ortuKelurahan: 'jatibening',
                //     ortuKodepos: '13000',
                //     ortuPhone: '888888',
                //     ortuNIKAyah: '111111',
                //     ortuNIKIbu: '222222',
                
                //     jalurPendaftaran: 'ZONASI',
                
                //     file: {
                //         photo: null,
                //         kk: null,
                //         aktaLahir: null,
                //         kms: null,
                //         mutasi: null,
                //         prestasi: [],
                //     },
                
                //     nilai: {
                //         mtk: { k_4_1: 3, k_4_2: 4, k_5_1: 5, k_5_2: 6, k_6_1: 7 },
                //         pkn: { k_4_1: 2, k_4_2: 3, k_5_1: 4, k_5_2: 5, k_6_1: 6 },
                //         indonesia: { k_4_1: 3, k_4_2: 4, k_5_1: 5, k_5_2: 6, k_6_1: 7 },
                //         ipa: { k_4_1: 4, k_4_2: 5, k_5_1: 6, k_5_2: 7, k_6_1: 8 },
                //         ips: { k_4_1: 5, k_4_2: 6, k_5_1: 7, k_5_2: 8, k_6_1: 9 }
                //     },
                //     reCaptcha: false
                // },

                // default form data
                form: {
                    schoolPropinsi: null,
                    schoolKabupaten: null,
                    schoolKecamatan: null,
                    schoolJenjang: null,
                    school: null,
                    userNamalengkap: null,
                    userEmail: null,
                    userPhone: null,
                    userPropinsi: null,
                    userKabupaten: null,
                    userKecamatan: null,
                    userKelurahan: null,
                    userAlamat: null,
                    userKodepos: null,
                    userLocLat: null,
                    userLocLng: null,
                    userAgama: null,
                    userNisn: null,
                    userNik: null,
                    userAsalSekolah: null,
                    userGender: null,
                    userTempatLahir: null,
                    userTglLahir: null,
                    userUkuranBaju: null,

                    ortuNamaAyah: null,
                    ortuJobAyah: null,
                    ortuNamaIbu: null,
                    ortuJobIbu: null,
                    ortuAlamat: null,
                    ortuPropinsi: null,
                    ortuKabupaten: null,
                    ortuKecamatan: null,
                    ortuKelurahan: null,
                    ortuKodepos: null,
                    ortuPhone: null,
                    ortuNIKAyah: null,
                    ortuNIKIbu: null,

                    jalurPendaftaran: null,

                    file: {
                        photo: null,
                        kk: null,
                        aktaLahir: null,
                        kms: null,
                        mutasi: null,
                        prestasi: [],
                    },

                    nilai: {
                        pkn: { k_4_1: null, k_4_2: null, k_5_1: null, k_5_2: null, k_6_1: null, },
                        indonesia: { k_4_1: null, k_4_2: null, k_5_1: null, k_5_2: null, k_6_1: null, },
                        mtk: { k_4_1: null, k_4_2: null, k_5_1: null, k_5_2: null, k_6_1: null, },
                        ipa: { k_4_1: null, k_4_2: null, k_5_1: null, k_5_2: null, k_6_1: null, },
                        ips: { k_4_1: null, k_4_2: null, k_5_1: null, k_5_2: null, k_6_1: null, }
                    },
                    reCaptcha: false
                },
                userPropinsiList: [ { text: '- Pilih propinsi -', value: null } ],
                userKabupatenList: [ { text: '- Pilih kabupaten -', value: null } ],
                userKecamatanList: [ { text: '- Pilih kecamatan -', value: null } ],
                ortuPropinsiList: [ { text: '- Pilih propinsi -', value: null } ],
                ortuKabupatenList: [ { text: '- Pilih kabupaten -', value: null } ],
                ortuKecamatanList: [ { text: '- Pilih kecamatan -', value: null } ],
                schoolPropinsiList: [ { text: '- Pilih propinsi -', value: null } ],
                schoolKabupatenList: [ { text: '- Pilih kabupaten -', value: null } ],
                schoolKecamatanList: [ { text: '- Pilih kecamatan -', value: null } ],
                schoolList: [ { text: '- Pilih sekolah -', value: null } ],

                // userPropinsiList: [ { text: '- Pilih propinsi -', value: 1 } ],
                // userKabupatenList: [ { text: '- Pilih kabupaten -', value: 1 } ],
                // userKecamatanList: [ { text: '- Pilih kecamatan -', value: 1 } ],
                // ortuPropinsiList: [ { text: '- Pilih propinsi -', value: 1 } ],
                // ortuKabupatenList: [ { text: '- Pilih kabupaten -', value: 1 } ],
                // ortuKecamatanList: [ { text: '- Pilih kecamatan -', value: 1 } ],
                // schoolPropinsiList: [ { text: '- Pilih propinsi -', value: 1 } ],
                // schoolKabupatenList: [ { text: '- Pilih kabupaten -', value: 1 } ],
                // schoolKecamatanList: [ { text: '- Pilih kecamatan -', value: 1 } ],
                // schoolList: [ { text: '- Pilih sekolah -', value: 1 } ],

                monthList: [
                    'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
                    'Juli', 'Agustus', 'September', 'Oktober', 'Novembet', 'Desember'
                ],
                file: {
                    photo: null,
                    kk: null,
                    aktaLahir: null,
                    kms:null,
                    mutasi: null,
                    prestasi: [
                        { file: null, judul: null, isSubmit: false, filename: null },
                    ]
                },
                fileSubmit: {
                    photo: false,
                    kk: false,
                    aktaLahir: false,
                    kms: false,
                    mutasi: false
                },
                validation: {
                    photo: null,
                    kk: null,
                    aktaLahir: null,
                },
                status: {
                    submit: false,
                    complete: false,
                },
                registration: {
                    code: null,
                    nisn: null,
                },
                schoolJenjangList: [
                    { text: '- Pilih jenjang -', value: null },
                    { text: 'Sekolah Dasar (SD)', value: '1' },
                    { text: 'Sekolah Menengah Pertama (SMP)', value: '2' },
                    { text: 'Sekolah Menengah Atas (SMA)', value: '3' },
                    { text: 'Madrasah Ibtidaiyah (MI)', value: '15' },
                    { text: 'Madrasah Tsanawiyah (MTs)', value: '14' },
                    { text: 'Madrasah Aliyah (MA)', value: '10' }
                ],
                userGenderList: [
                    { text: '- Pilih jenis kelamin -', value: null },
                    { text: 'Laki-laki', value: 'LAKI_LAKI' },
                    { text: 'Perempuan', value: 'PEREMPUAN' }
                ],
                userAgamaList: [
                    { text: '- Pilih agama -', value: null },
                    { text: 'Islam', value: 'ISLAM' },
                    { text: 'Kristen', value: 'KRISTEN' },
                    { text: 'Katolik', value: 'KATOLIK' },
                    { text: 'Hindu', value: 'HINDU' },
                    { text: 'Budha', value: 'BUDHA' },
                    { text: 'KongHuCu', value: 'KONG_HU_CU' },
                ],
                jalurPendaftaranList: [
                    { text: '- Pilih jalur pendaftaran -', value: null },
                    { text: 'Zonasi', value: 'ZONASI'},
                    { text: 'Afirmasi', value: 'AFIRMASI'},
                    { text: 'Prestasi', value: 'PRESTASI'},
                    { text: 'Mutasi Orang tua', value: 'ORTU_PINDAH'}
                ],
                ukuranBajuList: [
                    { text: '- Pilih ukuran baju -', value: null },
                    { text: 'Ukuran 8', value: '8' },
                    { text: 'Ukuran 9', value: '9' },
                    { text: 'Ukuran 10', value: '10' },
                    { text: 'Ukuran 11', value: '11' },
                    { text: 'Ukuran 12', value: '12' },
                    { text: 'Ukuran 13', value: '13' },
                    { text: 'Ukuran 14', value: '14' },
                    { text: 'Ukuran 15', value: '15' },
                    { text: 'Ukuran 16', value: '16' },
                    { text: 'Ukuran S', value: 'S' },
                    { text: 'Ukuran M', value: 'M' },
                    { text: 'Ukuran L', value: 'L' },
                    { text: 'Ukuran XL', value: 'XL' },
                    { text: 'Ukuran XXL', value: 'XXL' }
                ],
                schoolCache: null,
                show: true
            }
        },
        mounted() {
            console.log('state-form'),
            axios
                .get(this.$endpoint.propinsi)
                .then(
                    response => {
                        var data = [ { text: '- Pilih propinsi -', value: null, selected: true}]
                        response.data.forEach(function(item) {
                            data.push({text: item.name, value: item.id})
                        })
                        this.userPropinsiList = data
                        this.schoolPropinsiList = data
                        this.ortuPropinsiList = data
                        if (this.schoolPropinsi) {
                            this.updateKabupatenSekolah()
                        }
                        // console.log('this.form:', JSON.stringify(this.form))
                        // console.log('this.ppdb:', JSON.stringify(this.ppdb))
                    }
                )
                .catch(error => {
                    alert('Gagal terkoneksi ke server, periksa internet anda, atau refresh browser')
                    console.log(error)
                })
        },
        computed: {
            nisnState() {
                return (this.form.userNisn && this.form.userNisn.length >= 6) ? true : false
            }
        },
        methods: {
            pickSchool: function() {
                this.ppdb = []
                if (this.form.school && this.schoolCache) {
                    this.schoolCache.forEach(item => {
                        if (item.id==this.form.school) {
                            this.ppdb = item
                            this.mapSetup.ppdbLoc = latLng(this.ppdb.school.maps.lat, this.ppdb.school.maps.lng)
                            this.updatePath()
                            this.panMap()
                            let opUrl = "https://sekolah.soalonline.com/school/" + this.ppdb.school.code + "/ppdb"
                            this.$emit('changeOperatorLink', opUrl)
                        }
                    })
                }
            },
            tambahFilePrestasi: function() {
                this.file.prestasi.push({ file: null,  judul:null, isSubmit: false })
            },
            deleteFilePrestasi: function(id) {
                // console.log(id, this.form)
                this.file.prestasi.splice(id, 1)
            },
            updateSekolah: function() {
                this.schoolList = [{text: '- Pilih sekolah -', value: null}]
                this.form.school = null
                if (this.form.schoolJenjang && this.form.schoolKecamatan) {
                    let durl = this.$endpoint.sekolah
                    let ddata = {kecamatan: this.form.schoolKecamatan, jenjang: this.form.schoolJenjang}
                    // console.log(durl, ddata)
                    axios.post( durl, ddata )
                    .then(
                        (response) => {
                            // console.log(response.data)
                            if (response.data.status == true && response.data.data.length > 0) {
                                this.schoolCache = response.data.data
                                response.data.data.forEach(item => {
                                    this.schoolList.push({text: item.ppdb_name, value: item.id})
                                })
                            } else {
                                this.schoolList = [{text: '- Tidak ada mitra sekolah PPDB -', value: null}]
                            }
                        },
                        (error) => {
                            alert('Gagal terkoneksi ke server, periksa internet anda, atau refresh browser')
                            console.log(error)
                        }
                    )
                }
            },
            updateKabupatenOrangtua: function() {
                var propinsi = this.form.ortuPropinsi
                this.form.ortuKabupaten = null
                if(propinsi) {
                    var url = this.$endpoint.propinsi + "/" + propinsi
                    axios.get(url)
                         .then(response => {
                            var data = [ { text: '- Pilih kabupaten -', value: null, selected: true}]
                            response.data['child'].forEach(function(item) {
                                data.push({text: item.nama, value: item.key})
                            })
                            this.ortuKabupatenList = data
                         })
                }
            },
            updateKabupatenSekolah: function() {
                var propinsi = this.form.schoolPropinsi
                this.form.schoolKabupaten = null
                if(propinsi !=null || typeof propinsi !='undefined') {
                    var url = this.$endpoint.propinsi + "/" + propinsi
                    axios.get(url)
                         .then(response => {
                            var data = [ { text: '- Pilih kabupaten -', value: null, selected: true}]
                            if (response.data['child'].length) {
                                response.data['child'].forEach(function(item) {
                                    data.push({text: item.nama, value: item.key})
                                })
                            }
                            this.schoolKabupatenList = data
                         })
                }
            },
            updateKabupaten: function() {
                var propinsi = this.form.userPropinsi
                this.form.userKabupaten = null
                if(propinsi !=null || typeof propinsi !='undefined') {
                    var url = this.$endpoint.propinsi + "/" + propinsi
                    axios.get(url)
                         .then(response => {
                            var data = [ { text: '- Pilih kabupaten -', value: null, selected: true}]
                             if (response.data['child'].length) {
                                 response.data['child'].forEach(function (item) {
                                     data.push({text: item.nama, value: item.key})
                                 })
                             }
                            this.userKabupatenList = data
                         })
                }
            },
            updateKecamatanOrangtua: function() {
                var kabupaten = this.form.ortuKabupaten
                this.form.ortuKecamatan = null
                if(kabupaten !=null || typeof kabupaten !='undefined') {
                    var url = this.$endpoint.kabupaten + "/" + kabupaten
                    axios.get(url)
                         .then(response => {
                            var data = [ { text: '- Pilih kecamatan -', value: null, selected: true}]
                             if (response.data['child'].length) {
                                 response.data['child'].forEach(function (item) {
                                     data.push({text: item.nama, value: item.key})
                                 })
                             }
                            this.ortuKecamatanList = data
                         })
                }
            },
            updateKecamatanSekolah: function() {
                var kabupaten = this.form.schoolKabupaten
                this.form.schoolKecamatan = null
                if(kabupaten !=null || typeof kabupaten !='undefined') {
                    var url = this.$endpoint.kabupaten + "/" + kabupaten
                    axios.get(url)
                         .then(response => {
                            var data = [ { text: '- Pilih kecamatan -', value: null, selected: true}]
                             if (response.data['child'].length) {
                                 response.data['child'].forEach(function (item) {
                                     data.push({text: item.nama, value: item.id})
                                 })
                             }
                            this.schoolKecamatanList = data
                         })
                }
            },
            updateKecamatan: function() {
                var kabupaten = this.form.userKabupaten
                this.form.userKecamatan = null
                if(kabupaten !=null || typeof kabupaten !='undefined') {
                    var url = this.$endpoint.kabupaten + "/" + kabupaten
                    axios.get(url)
                         .then(response => {
                            var data = [ { text: '- Pilih kecamatan -', value: null, selected: true}]
                             if (response.data['child'].length) {
                                 response.data['child'].forEach(function (item) {
                                     data.push({text: item.nama, value: item.key})
                                 })
                             }
                            this.userKecamatanList = data
                         })
                }
            },
            onSubmit(event) {

                event.preventDefault()
                this.errors = null;
                this.submitError = null

                if (
                    !this.form.file.photo || !this.form.file.aktaLahir || !this.form.file.kk
                    || (this.form.jalurPendaftaran=='ORTU_PINDAH' && !this.form.file.mutasi)
                    || (this.form.jalurPendaftaran=='AFIRMASI' && !this.form.file.kms)
                ) {
                    this.errors = 'Berkas foto/file harus diupload lebih dulu'
                    return false
                }

                if (this.form.jalurPendaftaran=='PRESTASI') {
                    this.file.prestasi.forEach(function (item) {
                        if (!item.file && !item.filename) {
                            this.errors = 'Berkas foto/file harus diupload lebih dulu'
                            return false
                        }
                    })
                }

                if (!this.form.reCaptcha) {
                    this.errors = 'Verifikasi captcha salah'
                    return false
                }

                let form = JSON.stringify(this.form)
                console.log('form: ', form)
                console.log('error: ', this.errors)
                if (!this.errors) {
                    console.log( 'form ok', 'state-submit')
                    this.status.submit = true
                    this.state = 'SUBMIT'
                    axios.post(this.$endpoint.submit, form, { headers: { 'Content-Type': 'application/json'}})
                    .then( response => {
                         console.log('Submit response: ', response.data)
                        if (response.data.status==true) {
                            console.log('state-success'),
                            this.status.complete = true
                            this.state = 'SUCCESS'
                            if (response.data.success == false) {
                                console.log('ada error submit: ', response.data.message)
                                this.submitError = response.data.message
                            }

                            this.regData = response.data.data

                            // let durl = this.$endpoint.detail
                            // let ddata = {nisn:  response.data.data.nisn, kode_reg: response.data.data.kode_reg}
                            // console.log(durl, ddata)
                            // axios.post( durl, ddata )
                            //     .then(
                            //         (response) => {
                            //             console.log('Detail PPDB: ', response.data)
                            //             if (response.data.status == true) {
                            //                 this.regData = response.data.data
                            //             }
                            //         },
                            //         (error) => {
                            //             alert('Gagal terkoneksi ke server, periksa internet anda, atau refresh browser')
                            //             console.log(error)
                            //         }
                            //     )
                        }
                     })
                }
            },
            onVerify: function () {
                this.form.reCaptcha = true
                // console.log('Verify: ' + response)
            },
            onExpired: function () {
                // console.log('Expired')
                this.$refs.recaptcha.reset()
                this.form.reCaptcha = false
            },
            resetFile(id) {
                this.form.file[id] = null
                this.fileSubmit[id] = false
            },
            upload2Cloud(id) {
                if ( this.ppdb.id && this.form.userNisn && this.file[id] ) {
                    // console.log(this.file[id])
                    if (this.file[id].size>1024*1024 || this.file[id].type!= "image/jpeg") {
                        alert('Format gambar harus jpeg, ukuran max 1MB')
                        return false
                    }
                    this.fileSubmit[id] = true
                    // console.log(id, this.file[id])
                    fetch(this.$endpoint.upload, {
                        method: 'POST',
                        headers: {
                            'x-filename': this.file[id].name,
                            'x-nisn': this.form.userNisn,
                            'x-ppdb' : this.ppdb.id
                        },
                        body: this.file[id]
                    })
                    .then(response => response.json())
                    .then(data => {
                        // console.log('Success:', data);
                        if ( data.status == true) {
                            this.form.file[id] = data.filename
                            this.fileSubmit[id] = false
                            // console.log(JSON.stringify(this.form))
                        }
                    })
                    .catch( error => {
                        console.log(error)
                        return false
                    })
                }
                else {
                    alert('lengkapi data form sebelum upload foto.')
                    console.log('nofile')
                }
            },
            resetFilePrestasi(id) {
                this.file.prestasi[id].filename = null
                this.file.prestasi[id].isSubmit = false
                this.form.file.prestasi[id] = null
                console.log(this.form)
            },
            upload2CloudPrestasi(id) {
                if (this.ppdb.id && this.form.userNisn && this.file.prestasi[id].file && this.file.prestasi[id].file.size<=1024*1024 ) {
                    this.file.prestasi[id].isSubmit = true
                    // console.log(id, this.file.prestasi[id].file)
                    fetch(this.$endpoint.upload, {
                        method: 'POST',
                        headers: {
                            'x-filename': this.file.prestasi[id].file.name,
                            'x-nisn': this.form.userNisn,
                            'x-ppdb' : this.ppdb.id
                        },
                        body: this.file.prestasi[id].file
                    })
                        .then(response => response.json())
                        .then(data => {
                            // console.log('Success:', data);
                            if ( data.status == true ) {
                                this.file.prestasi[id].filename = data.filename
                                this.file.prestasi[id].isSubmit = false
                                this.form.file.prestasi[id] = {
                                    filename: data.filename,
                                    judul: this.file.prestasi[id].judul
                                }
                                // console.log(JSON.stringify(this.form))
                            }
                        })
                        .catch( error => {
                            console.log(error)
                            return false
                        })
                }
                else {
                    console.log('nofile')
                }
            },
            generatePdf(){
                const doc = new jsPDF('p', 'pt', 'A4')
                // console.log('jsPDF is: ', doc)
                doc.html(this.$refs.ppdbSummary, {
                    callback: function (pdf) {
                        let regCode = document.getElementById("kode-reg").textContent
                        pdf.save("PPDB_" + regCode + ".pdf")
                    },
                    x: 10,
                    y: 10,
                })
            },
            startForm() {
                this.state = 'FORM'
            },
            logForm() {
                console.log(this.form)
            },
            findloc() {
                const myMap = this.$refs.myMap.mapObject
                console.log(myMap)
                navigator.geolocation.getCurrentPosition((position) => {
                    let loc = latLng(position.coords.latitude, position.coords.longitude)

                    let marker = this.$refs.marker
                    // console.log('marker: ', marker)
                    if (marker) {
                        marker.setLatLng(loc)
                    }
                    myMap.flyTo(loc, 14)
                    this.mapSetup.center = loc
                    this.mapSetup.zoom = 14
                    if (!this.form.userLocLat) {
                        this.mapSetup.userLoc = loc
                        this.form.userLocLat = this.mapSetup.userLoc.lat
                        this.form.userLocLng = this.mapSetup.userLoc.lng
                    }
                    this.updatePath()
                });
                
                // console.log(this.mapSetup.center, this.mapSetup.zoom)
            },
            setMarker(event) { // set marker during first load
                this.mapSetup.userLoc = event.latlng
                // console.log('setmarker: ', this.mapSetup.userLoc)

                this.form.userLocLat = this.mapSetup.userLoc.lat
                this.form.userLocLng = this.mapSetup.userLoc.lng
                this.updatePath()
            },
            updateMarker() { // set marker on map click
                this.mapSetup.userLoc = this.$refs.marker.mapObject.getLatLng()
                // console.log('update marker: ', this.mapSetup.userLoc)

                this.form.userLocLat = this.mapSetup.userLoc.lat
                this.form.userLocLng = this.mapSetup.userLoc.lng
                this.updatePath()
            },
            panMap() {
                if (this.mapSetup.userLoc) {
                    if(this.mapSetup.ppdbLoc) {
                        let locBound = latLngBounds(this.mapSetup.userLoc, this.mapSetup.ppdbLoc)
                        this.$refs.myMap.fitBounds(locBound.pad(0.2))
                    } else {
                        this.$refs.myMap.mapObject.flyTo(this.mapSetup.userLoc, 14)
                    }
                }
            },
            updatePath() {

                if (!this.mapSetup.userLoc || !this.mapSetup.ppdbLoc) {
                    this.mapSetup.zonePath = null
                    this.mapSetup.distancePath = 0
                    return
                }

                //update polyline path
                this.mapSetup.zonePath = [this.mapSetup.userLoc,this.mapSetup.ppdbLoc]
                this.mapSetup.distancePath = this.mapSetup.ppdbLoc.distanceTo(this.mapSetup.userLoc)

            },            
        }
    }
</script>